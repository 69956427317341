@import (reference) '../../../styles/globals.less';

@gallery-card-height: 556px;
@gradient-padding-top: 88px;

.gallery-card {
  > article[data-testid^='gallery-card-component'] {
    height: @gallery-card-height;
  }

  &__half {
    > article[data-testid^='gallery-card-component'] {
      height: calc(@gallery-card-height / 2);

      div[class^='_title-gradient_'] {
        padding-top: calc(@gradient-padding-top / 2);
      }
    }
  }
}
