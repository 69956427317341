@import (reference) "../../../styles/globals.less";

.container {
  width: calc(100% - (2 * @spacing-16px));
  max-width: 1200px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 @spacing-16px;

  @media (min-width: @screen-sm-min) {
    width: calc(100% - (2 * @spacing-24px));
    padding: 0 @spacing-24px;
  }

  @media (min-width: @screen-md-min) {
    width: calc(100% - (2 * @spacing-40px));
    padding: 0 @spacing-40px;
  }

  &.padding-disabled {
    width: 100%;
    padding: 0;
  }
}
