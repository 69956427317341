@import (reference) '../../../styles/globals.less';

@svg-desktop-size: 130px;
@svg-mobile-size: 48px;

.video-component {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;

  .video-poster {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-size: cover;
    background-position: center;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 42.33%,
        rgba(0, 0, 0, 0.8) 87.11%
      );
    color: #fff;
    text-align: center;
    padding: @spacing-20px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }
  }

  .svg-play-button {
    cursor: pointer;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .text-container {
    position: absolute;
    width: 100%;
    max-width: 570px;
    bottom: 63px;

    > h2 {
      font-size: clamp(@spacing-24px, 5vw, @spacing-40px);
      line-height: clamp(@spacing-32px, 6vw, @spacing-48px);
      margin-bottom: 5px;
    }

    > p {
      font-size: clamp(14px, 4vw, @spacing-24px);
      line-height: clamp(22px, 5vw, @spacing-32px);
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  .hidden {
    display: none;
  }

  @media (max-width: @screen-md-max) {
    .text-container {
      height: auto;
      bottom: clamp(@spacing-12px, 6vw, 49px);
      max-width: clamp(369px, 50vw, 570px);

      > h2 {
        font-size: clamp(@spacing-16px, 5vw, @spacing-24px);
        line-height: clamp(@spacing-24px, 6vw, @spacing-32px);
      }

      > p {
        font-size: clamp(14px, 4vw, @spacing-16px);
        line-height: clamp(22px, 5vw, @spacing-24px);
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .text-container {
      max-width: 570px;
      bottom: 63px;

      > h2 {
        font-size: 40px;
        line-height: @spacing-48px;
      }

      > p {
        font-size: @spacing-24px;
        line-height: 32px;
      }
    }

    .svg-play-button {
      width: clamp(@svg-mobile-size, 10vw, @svg-desktop-size);
      height: clamp(@svg-mobile-size, 10vw, @svg-desktop-size);
    }
  }
}

@media (max-width: @screen-xs) {
  .svg-play-button {
    width: @svg-mobile-size;
    height: @svg-mobile-size;
    margin-top: -48px;
  }

  .text-container {
    bottom: 12px;

    > h2 {
      font-size: @spacing-16px;
      line-height: @spacing-24px;
      font-weight: 350;
    }

    > p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
