@import (reference) '../../../styles/globals.less';

.content-card-container {
  padding-bottom: @spacing-24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: @spacing-32px;
  row-gap: @spacing-32px;

  @media (min-width: @screen-sm-min) {
    padding-bottom: @spacing-32px;
  }

  .card-wrapper {
    flex: 0 0 100%;
    box-sizing: border-box;

    @media (min-width: @screen-sm-min) {
      flex: 1;
    }
  }

  .cards-3 {
    @media (max-width: @screen-sm-min) {
      flex: 0 0 100%;
    }
  }

  .cards-4 {
    @media (max-width: @screen-md-min) {
      // 990px
      flex: 0 0 calc(50% - 16px);
    }

    @media (max-width: @screen-sm-min) {
      flex: 0 0 100%;
    }
  }

  article[data-testid^='content-card-component'] {
    & div[class*='_image--portrait_'] {
      aspect-ratio: @image-aspect-ratio;
      min-height: auto;
    }

    & div[class*='_text_'] {
      margin: 0;

      &:has(+ div[class*='_button_']) {
        margin: 0 0 @spacing-24px 0;
      }
    }
  }
}
