@import (reference) '../../../styles/globals.less';

@silver: #99a4af;
@gold: #bda05d;
@purple: #4f145b;
@black: #222222;
@background-opacity: 0.05;

.table-card {
  border-radius: 4px;
  max-width: 639px;

  &__color-general {
    background-color: #f5f5f6;
    padding-top: @spacing-16px;
    .table-card__color-band {
      height: 0;
    }
  }

  &__color-band {
    height: @spacing-16px;
    border-radius: 4px 4px 0 0;
    width: 100%;
  }

  &__general {
    background: #f5f5f6;
  }

  &.table-card__band {
    border: 0 solid #e6e7e8;
    border-width: 0 1px 1px;
  }

  &__band {
    &.table-card__color-red {
      .table-card__color-band {
        background: var(--token-color-colour-static-red);
      }
    }
    &.table-card__color-silver {
      .table-card__color-band {
        background: @silver;
      }
    }
    &.table-card__color-gold {
      .table-card__color-band {
        background: @gold;
      }
    }
    &.table-card__color-purple {
      .table-card__color-band {
        background: var(--token-color-colour-purple-60);
      }
    }
    &.table-card__color-black {
      .table-card__color-band {
        background: @black;
      }
    }
  }

  &__full {
    &.table-card__color-red {
      background: fade(#da0530, 3.5%);
      .table-card__color-band {
        background: var(--token-color-colour-static-red);
      }
    }
    &.table-card__color-silver {
      background: fade(@silver, 5%);
      .table-card__color-band {
        background: @silver;
      }
    }
    &.table-card__color-gold {
      background: fade(@gold, 5%);
      .table-card__color-band {
        background: @gold;
      }
    }
    &.table-card__color-purple {
      background: fade(@purple, 5%);
      .table-card__color-band {
        background: var(--token-color-colour-purple-60);
      }
    }
    &.table-card__color-black {
      background: fade(@black, 5%);
      .table-card__color-band {
        background: @black;
      }
    }
  }

  &__label-position-left {
    label {
      order: -1;
      margin: 0 10px 0 0;
    }
  }

  &__label-position-top {
    label {
      display: block;
      order: -1;
      margin-bottom: 5px;
    }

    .table-card__list-item {
      flex-direction: column;
      align-items: flex-start;
      .table-card__list-label {
        margin-bottom: 0;
      }
    }
  }

  &__label-position-bottom {
    .table-card__list-item {
      flex-direction: column;
      align-items: flex-start;
      .table-card__list-label {
        display: block;
        margin-top: 5px;
      }
    }
  }

  &__label-position-right, &__label-position-left {
    label {
      margin-left: @spacing-8px;
    }

    .table-card__list--main {
      .table-card__list-item--overflow {
        flex-direction: column;
        align-items: flex-start;
  
        > label {
          margin: 4px 0;
        }
      }
    }

    .table-card__list--sub {
      .table-card__list-item {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }

  &__inner {
    padding: @spacing-16px;
  }

  &__heading {
    margin-bottom: 12px;
  }

  &__list {
    list-style: none;
    padding: 0;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: @spacing-8px;
    }

    &-item-inner {
      display: flex;
      gap: @spacing-8px;
    }
  }

  &__list--sub {
    > li {
      flex-direction: row;
    }
  }

  &__subheading {
    margin: @spacing-16px 0 12px;
  }

  &__button {
    color: var(--token-color-colour-static-red);
  }
}

.table-card__band {
  .table-card__inner {
    border: 1px solid #e6e7e8;
  }
}

.table-card__list {
  margin-bottom: 16px;
}

.table-card__list-item {
  overflow: hidden;

  > span {
    margin-inline-end: 10px;
  }

  > label {
    color: #676d73;
  }
}
