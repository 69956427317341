@import (reference) '../../../styles/globals.less';

.search-toggle {
  position: relative;
  z-index: 5;

  @media (min-width: @screen-md-min) {
    z-index: 4;
  }

  & .toggler {
    position: relative;
    display: block;
    width: 90%;
    margin: 10px auto;

    @media (min-width: @screen-sm-min) {
      display: none;
    }

    &+.togglee {
      display: none;

      @media (min-width: @screen-sm-min) {
        display: block;
      }
    }
  }

  .toggled+.togglee {
    display: block;
  }

  & section[aria-label*="Search Panel"] {
    @media (min-width: @screen-sm-min) {
      margin-bottom: 0;
    }
  }
}