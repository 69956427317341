@import (reference) '../../../styles/globals.less';

.content-card {
  padding-bottom: @spacing-24px;

  @media (min-width: @screen-sm-min) {
    padding-bottom: @spacing-32px;
  }

  @media screen and (max-width: @screen-xs-min) {

    & article[data-testid*='content-card-component'] {
      
      & div[class*='_content--theme-inherit_'] {
        padding: 24px 0;
      }
    }
  }

  &__parent--carousel {
    width: 100%;
  }

  &__link-image {
    & div[class*='_image_'] {
      transition: opacity 0.5s ease;
      opacity: 1;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }
}
