@import (reference) '../../../styles/globals.less';

.icon-panel {

    &--small {
        svg {
            width: 24px;
            height: 24px;
            stroke-width: 1.5px;
        }
    }

    &--medium {
        svg {
            width: 32px;
            height: 32px;
            stroke-width: 1.5px;
        }
    }

    &--large {
        svg {
            stroke-width: 2px;
            height: 48px;
            width: 48px;
        }
    }

}